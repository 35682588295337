import React, { useState, useEffect }  from "react";
import Blog from "../elements/Blog";
import Pagetitle from "../elements/Pagetitle";
import CodeSnippet from '../elements/CodeSnippet';
import axios from 'axios';

function Blogs() {

  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const result = await axios(
        'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@nievesjesus',
      );
  
      const allPost = result.data.items.filter(post => post.categories.length > 0 ).map(post => {
        let item =   {
          id: post.guid,
          title: post.title,
          image: post.thumbnail,
          filesource: post.link,
          date: post.pubDate,
          author: post.autor,
          category: post.categories[0]
        }
        return item
      }).filter((val,i)=>i<3)
      setData(allPost);
    }    
    fetchData()
  }, [])

  return (
    <section id="blog">
      <div className="container">

        <Pagetitle title={<CodeSnippet text={'// Get lastest posts\npost.sort { $0.date > $1.date }'} language="swift"/>} />        
        <div className="row blog-wrapper">
          {data.map((blogItem) => (
            <div className="col-md-4" key={blogItem.id}>
              <Blog blogData={blogItem} />
            </div>
          ))}
        </div>
        <div className="text-center">
          <div className="spacer" data-height="30"></div>
          <a href="https://medium.com/@nievesjesus" className="btn btn-default">
            Show all blogs
          </a>
        </div>
      </div>
    </section>
  );
}

export default Blogs;
