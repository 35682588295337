import React from "react";
import { Link } from "react-scroll";
import CodeSnippet from "../elements/CodeSnippet";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

const servicesData = [
  {
    id: 1,
    icon: "images/icons8-xcode.svg",
    title: "iOS Development",
    content:
      "This is my main skill, the one I love the most. I currently work as a full time iOS developer.",
    color: "#FF5722",
    contentColor: "light",
  },
  {
    id: 2,
    icon: "images/vscode.svg",
    title: "FrontEnd Development",
    content:
      "Thanks to my experience as a freelancer I was able to learn and grow doing frontend development",
    color: "#4CAF50",
    contentColor: "light",
  },
  {
    id: 3,
    icon: "images/terminal.svg",
    title: "Backend Development",
    content:
      "This is something I used to do in the old days with PHP, then I moved to Node and stayed there.",
    color: "#607D8B",
    contentColor: "light",
  } 
];

function Services() {
  return (
    <section id="services">
      <div className="container">
        <Pagetitle title={<CodeSnippet text={'// Display main profesional skills \nclass Skills: UIDevSkills'} language="swift" />} />
        <div className="row fix-spacing">
          {servicesData.map((service) => (
            <div className="col-md-4" key={service.id}>
              <Service service={service} />
            </div>
          ))}
        </div>
        <div className="mt-5 text-center">
          <p className="mb-0">
            Looking for a custom job?{" "}
            <Link
              className="colorpink pointer"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Click here
            </Link>{" "}
            to contact me! 👋
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
