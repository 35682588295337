import React, { useState, useEffect } from "react";
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import About from "../components/sections/About";
import Blogs from "../components/sections/Blogs";
//import Brandlogos from "../components/sections/Brandlogos";
import Contact from "../components/sections/Contact";
import Experiences from "../components/sections/Experiences";
import Herosection from "../components/sections/Herosection";
//import Pricing from "../components/sections/Pricing";
import Services from "../components/sections/Services";
import Testimonials from "../components/sections/Testimonials";
import Works from "../components/sections/Works";
import axios from "axios";
import Header3 from "../components/layouts/Header3";
import Loading from "../components/elements/Loading";

const Homepage = ()  => {
  const [loading, setLoading ] = useState(false);
  const [projects, setProjects] = useState(false);
  const [workExperience, setWorkExperience] = useState([]);
  const [academyExperience, setAcademyExperience] = useState([]);

  const shuffle = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      const result = await axios(
        'https://gitconnected.com/v1/portfolio/nievesjesus',
      );
  
      const getImage = (images) => {
        let currentImage = ""
        if (images.length > 0){
          let currentImageIndex = Math.floor(Math.random() * images.length)
          if (currentImageIndex >= images.length) {
            currentImageIndex = images.length - 1
          } 
          currentImage = images[currentImageIndex].resolutions.mobile.url
        }
        return currentImage
      }

  
      const getFullImage = (images) => {
        let currentImage = []
        for (let index = 0; index < images.length; index++) {
          const element = images[index];
          currentImage[index] = element.resolutions.desktop.url
        }
        return currentImage
      }      

      const getYear  = (date) => {
        let year = ""
        if (typeof date.start.year !== 'undefined' && date.start.year !== null) {
          year = `${date.start.year} - `
        }
        if (typeof date.end.year !== 'undefined') {
          year = `${year}${date.end.year}`
        }
        if (date.isCurrentRole === true) {
          year =`${year}Present`
        }
        return year
      }

      shuffle(result.data.projects)
      let resultProjects = result.data.projects.map((project, index) => {
          let item = {    
            id: index + 1,
            title: project.displayName,
            category: project.website,
            summary: project.summary,
            image: getImage(project.images),
            link: null,
            popupLink: getFullImage(project.images),
            libraries: `Language: ${project.languages.toString()}. Libraries: ${project.libraries.toString()}`
          }
          return item
      })

      let resultAcademyExperience = result.data.education.map((academy, index) => {
        let item =  {
          id: index,
          title: `${academy.area}`,
          years: `${getYear(academy)}`,
          content: `${academy.institution}: ${academy.studyType}`,
        }
        return item
      })

      let resultWorkExperience = result.data.work.map((work, index) => {
        let item =  {
          id: index,
          title: `${work.position}`,
          company: work.company,
          years: getYear(work),
          content: work.summary,
        }
        return item
      })

      setProjects(resultProjects)
      setWorkExperience(resultWorkExperience)
      setAcademyExperience(resultAcademyExperience)
      setLoading(false)
    }    
    fetchData()
  }, [])

  return (
    <>
      <Header3 logoSource="/images/logo-b-light.svg" />
      <main className={"content-3"}>
        <Element name="section-home">
          <ReactCursorPosition>
            <Herosection />
          </ReactCursorPosition>
        </Element>
        <Element name="section-about">
          <About />
        </Element>
        <Element name="section-experiences">
          <Experiences academyExperience={academyExperience} workExperience={workExperience} />
        </Element>        
        {projects &&
        <Element name="section-works">
          <Works projects={projects} />
        </Element>        
        }        
        <Element name="section-services">
          <Services />
        </Element>
        {/* <Element name="section-pricing">
          <Pricing />
        </Element> */}
        <Element name="section-testimoninal">
          <Testimonials />
        </Element>
        {/* <Element name="section-brandlogos">
          <Brandlogos />
        </Element> */}
        <Element name="section-blogs">
          <Blogs />
        </Element>
        <Element name="section-contact">
          <Contact />
        </Element>
        <div className="spacer" data-height="96"></div>
      </main>
      {loading ? <Loading /> : null}
    </>
  );
}

export default Homepage;
