import React, { useState, useEffect, Fragment } from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";
import CodeSnippet from '../elements/CodeSnippet';


function Experiences({workExperience, academyExperience}) {
  const [filteredAcademyExperiences, setFilteredAcademyExperiences] = useState([])
  const [filteredExperiences, setFilteredExperiences] = useState([])

  const [showCertifications, setShowCertifications] = useState(false)  
  const [showMoreWork, setShowMoreWork] = useState(false)    

  useEffect(() => {
    hideCertifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [academyExperience])

  useEffect(() => {
    lessWork()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workExperience])  

  useEffect(() => {
    if (showCertifications === true) {
      showCertificationsPressed()
    } else {
      hideCertifications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCertifications])

  useEffect(() => {
    if (showMoreWork === true) {
      moreWork()
    } else {
      lessWork()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMoreWork])  

  const showCertificationsPressed = () => {
    setFilteredAcademyExperiences(academyExperience)
  }

  const hideCertifications = () => {
    let filtered = academyExperience.filter(experience => experience.title !== "Certification")
    setFilteredAcademyExperiences(filtered)
  }

  const toogleCertifications = () => {
    setShowCertifications(!showCertifications)
  }

  const moreWork = () => {
    setFilteredExperiences(workExperience)
  }

  const lessWork = () => {
    let filtered = workExperience.filter(work => work.id === 0)
    setFilteredExperiences(filtered)
  }

  const toogleWorks = () => {
    setShowMoreWork(!showMoreWork)
  }

  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title={<CodeSnippet text={'// Show wherever Jesus has or not some kind of experiences \nvar hasExperience:Bool?'} language="swift" />} />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {filteredAcademyExperiences.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>

              <button
                style={{marginTop: "20px"}}
                className="btn colorpink pointer"
                onClick={toogleCertifications}
              >
                <span>
                  <i className="fas fa-spinner"></i> {!showCertifications ? "Show additional certifications" : "Hide additional certifications"}
                </span>
              </button>              
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {filteredExperiences &&
                <Fragment>
              {filteredExperiences.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}                  
                </Fragment>              
              }
              <span className="line"></span>

              <button
                style={{marginTop: "20px"}}
                className="btn colorpink pointer"
                onClick={toogleWorks}
              >
                <span>
                  <i className="fas fa-spinner"></i> {!showMoreWork ? "Show more work experience" : "Show less work experience"}
                </span>
              </button>               
            </div>
          </div>          
        </div>
      </div>
    </section>
  );
}

export default Experiences;
