import React from 'react';
import PropTypes from 'prop-types';
import Prism from 'prismjs';

const CodeSnippet = ({ language, text }) => (

    <code
      className={`language-${language}`}
      dangerouslySetInnerHTML={{
        __html: Prism.highlight(text, Prism.languages[language], language),
      }}
    />
);

CodeSnippet.propTypes = {
  text: PropTypes.string.isRequired
};

export default CodeSnippet;