import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function Portfolio({ portfolio }) {
  const { category, title, image, popupLink, link, summary, libraries } = portfolio;
  const [toggler, setToggler] = useState(false);

  const handleLightbox = (e) => {
    if (!link) {
      e.preventDefault();
      setToggler(!toggler);
    }
  };

  const handleIcon = () => {
    if (link) {
      return <i className="icon-link"></i>;
    } else if (popupLink) {
      if (popupLink.length > 1) {
        if (popupLink.toString().match(/youtube/g)) {
          return <i className="icon-camrecorder"></i>;
        }
        return <i className="icon-eye"></i>;
      } else if (popupLink.toString().match(/youtube/g)) {
        return <i className="icon-camrecorder"></i>;
      } else {
        return <i className="icon-magnifier-add"></i>;
      }
    }
    return <i className="icon-magnifier-add"></i>;
  };
  return (
    <>
      <a
        href={link ? link : "!#"}
        className="work-image"
        onClick={handleLightbox}
      >
        <div style={{height: '240px'}} className="portfolio-item rounded shadow-dark">
          <div className="details">
            <span className="term">{category}</span>
            <h4 className="title">{title}</h4>  
            <h4 className="subtitle">{summary}</h4> 
            <p className="description">{libraries}</p>      
            <span className="more-button">{handleIcon()}</span>
          </div>
          <div className="thumb">
            <img src={image} alt={title} />
            <div className="mask"></div>
          </div>
        </div>
      </a>
      {popupLink && <FsLightbox type="image" toggler={toggler} sources={popupLink} />}
    </>
  );
}

export default Portfolio;
