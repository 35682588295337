import React from "react";
import Slider from "react-slick";
import Pagetitle from "../elements/Pagetitle";
import Testimonial from "../elements/Testimonial";
import CodeSnippet from '../elements/CodeSnippet';

const sliderData = [
  {
    id: 1,
    avatar: "images/reviews/naitcorp.png",
    userName: "Christian Pazmino 🇪🇨",
    subtitle: "Owner at naitcorp",
    review:
      "Jesus, continues to show great professionalism in all aspects, the problems that have arisen were resolved and a fluid communication was created. The work done is of very good quality",
  },
  {
    id: 2,
    avatar: "images/reviews/leo-salinas.jpeg",
    userName: "Leo Salinas 🇨🇱",
    subtitle: "Events & Content Producer specialized in esports.",
    review:
      "Although we took longer than stipulated, it was not Jesus' responsibility ... these are normal stages of a project. Jesus has been very responsible, fast and efficient to fulfill all the requirements. Very concerned with good work and good practices. Excellent resource!",
  },
  {
    id: 3,
    avatar: "images/reviews/hyh.jpg",
    userName: "Eddy Hernandez 🇩🇴",
    subtitle: "CEO H&H Associated",
    review:
      "I am very satisfied with the final product, and I fully recommend your services to anyone who considers quality to be important for the development of their business.",
  },
  {
    id: 4,
    avatar: "images/reviews/a-polanco.jpeg",
    userName: "Alfonso Polanco 🇲🇽",
    subtitle: "Sales Executive at Vistana Signature Experiences",
    review:
      "Jesús Manuel is a professional, he is a person who maintains constant communication with the client ... and most importantly, his attention to the details found by the client during the development of the project. I RECOMMEND IT 200% and I would hire it again. THANK YOU JESUS.",
  },
  {
    id: 5,
    avatar: "images/reviews/gonza.jpg",
    userName: "Gonzalo Benoffi 🇦🇷",
    subtitle: "Software Developer - HuTechnology",
    review:
      "Very well worked. Punctual with the schedule and deadlines. Very friendly. Very good knowledge. Recommended!",
  },  
  {
    id: 6,
    avatar: "images/reviews/Logo-funcion-05.jpg",
    userName: "Funcion 🇻🇪",
    subtitle: "Professional business solutions Web, Destok & App",
    review:
      "Excellent Frontend programmer, recommended",
  },   
  {
    id: 7,
    avatar: "images/reviews/ari.jpeg",
    userName: "Ariel Lichtin 🇦🇷",
    subtitle: "Sr Gaming Mobile Developer - ROBLOX",
    review:
      "Jesus Manuel is a excellent developer. I definitively look forward to work with you again soon",
  },   
  {
    id: 8,
    avatar: "images/reviews/francisca.jpeg",
    userName: "Francisca Kraljevic 🇨🇱",
    subtitle: "Product Manager 4Kids en GRUPO LIMONADA",
    review:
      "Excellent programmer, very detailed and always attentive to requests for changes or doubts.",
  }, 
  {
    id: 9,
    avatar: "images/reviews/walter.jpeg",
    userName: "Walter Esteban Juarez Rivas 🇦🇷",
    subtitle: "CEO & CoFounder at @RollingCode",
    review:
      "I am truly grateful to have been able to contact Jesus. It gave us a new vision of it technically and improved some things that were not even agreed. He even managed to deal with some of our miscommunication. It is completely recommended. Also we will continue working together, a pleasure to work with",
  },       
  {
    id: 10,
    avatar: "images/reviews/cropped-tdb.png",
    userName: "Pablo Andrés Mayorga 🇨🇴",
    subtitle: "The Digital Booster (TDB)",
    review:
      "An excellent professional, Jesus is the right hand of our work team with a complete team of dedicated and talented professionals in their projects. Super recommended!",
  },
  {
    id: 11,
    avatar: "images/reviews/theux.png",
    userName: "Juan P Manson 🇦🇷",
    subtitle: "The UX Department",
    review:
      "Working with Jesus is always a pleasure. Very good quality of work and attention",
  },              
  {
    id: 12,
    avatar: "images/reviews/dylan.jpg",
    userName: "Dylan Irzi 🇨🇴",
    subtitle: "Digital Marketing Expert",
    review:
      "I would work a thousand times with this development professional! very fast and effective to work on any project",
  },   
  {
    id: 13,
    avatar: "images/reviews/mica.png",
    userName: "Mica Rosental 🇦🇷",
    subtitle: "Strategy Campaign in Google Adwords",
    review:
      "Excellent professional. Very attentive, neat and responsible. Without a doubt, I would work with him again.",
  },
  {
    id: 14,
    avatar: "images/reviews/icono_truco.png",
    userName: "Juan Uy 🇺🇾",
    subtitle: "Developer",
    review:
      "Jesus is very dedicated, he commits himself to work beyond what is requested. Good and constant communication",
  },       
  {
    id: 15,
    avatar: "images/reviews/ulises.png",
    userName: "Ulises Bailon 🇲🇽",
    subtitle: "Growth hacker and SEO expert",
    review:
      "Excellent developer, we continue to work with him. Like many freelancers, some problems in time and communication but in his case they are slight.",
  },    
  {
    id: 16,
    avatar: "images/reviews/andy.png",
    userName: "Andres Gonzales 🇺🇾",
    subtitle: "CEO HuecoCreativo",
    review:
      "A real pleasure to have worked with Jesus, he knows how to take on challenges, quick and concrete response, with results that exceed expectations. The truth is that we are very satisfied to work with him and his team, a pleasure that he is part of this project, we will continue working",
  }, 
  {
    id: 17,
    avatar: "images/reviews/cecilia.jpg",
    userName: "Cecilia Rojas Lopez 🇨🇱 ",
    subtitle: "",
    review:
      "Jesus Manuel was super professional. He clarified all the doubts and managed to solve my problem on the page. Super fluid communication. =)",
  },
  {
    id: 18,
    avatar: "images/reviews/jocy.png",
    userName: "Jocy Hernandez 🇺🇸",
    subtitle: "Royalty Sports Manager",
    review:
      "Very professional treatment and always willing to modify as new ideas arise",
  },            
  
];

function Testimonials() {
  const slidetSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="testimonials">
      <div className="container">
        <Pagetitle title={<CodeSnippet text={'// Clients and Partners reviews\nlet reviews = [...review]'} language="swift" />} />
        <Slider {...slidetSettings} className="testimonials-wrapper">
          {sliderData.map((slider) => (
            <Testimonial key={slider.id} slider={slider} />
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
